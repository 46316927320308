import { Elements } from "@stripe/react-stripe-js";
import { PropsWithChildren, ReactElement } from "react";
import { ElementsOptions } from "./constants";
import { useLoadStripe } from "./hooks";
import ErrorMessage from "@common/components/text/ErrorMessage";
import { Loader } from "@mantine/core";

export default function StripeProvider({
  children,
}: PropsWithChildren): ReactElement {
  if (!process.env.STRIPE_KEY) {
    throw new Error("StripeProvider, `STRIPE_KEY` is missing");
  }

  // load stripe
  const { loading, stripe } = useLoadStripe(process.env.STRIPE_KEY);

  // loading
  if (loading) {
    return <Loader size="xs" />;
  }
  // error
  else if (!loading && !stripe) {
    return (
      <ErrorMessage>
        An error occured when trying to load the payment form.
        <br />
        Please check your connection or browser extensions.
      </ErrorMessage>
    );
  }

  // success
  return (
    <Elements options={ElementsOptions} stripe={stripe}>
      {children}
    </Elements>
  );
}
