import { PLAN_STARTER } from "./constants";
import { AllPlanSlugType, IGetPlanAliasProps, PlanSlugType } from "./types";

export function getRawPlanAlias(alias: string): PlanSlugType {
  return (
    alias
      .replace("-cloud", "")
      .replace("-self-hosted", "")
      .replace("-yearly", "")

      // LEGACY
      // starter-v2 => starter
      .replace("starter-v2", "starter")
      // startup => starter
      .replace("startup", "starter")

      // TEMP
      .replace("-with-dwh", "") as PlanSlugType
  );
}

export function isProPlan(alias: string): boolean {
  return (
    alias.includes("pro-") ||
    // TO BE REMOVED
    // This will be removed once we fully use the new plans API
    alias === "pro"
  );
}

export function isEnterprisePlan(alias: string): boolean {
  return alias.includes("enterprise-");
}

export function isDWHPlan(alias: string): boolean {
  return alias.includes("-with-dwh");
}

export function isSelfHostedPlan(alias: string): boolean {
  return !(alias.includes("-cloud") || alias.includes(PLAN_STARTER));
}

export function isYearlyPlan(alias: string): boolean {
  return alias.includes("-yearly");
}

// TO BE REVIEWED
export function getPlanAlias({
  planSlug,
  isSelfHosted,
  isAnnualBilling,
}: IGetPlanAliasProps): AllPlanSlugType {
  const deploymentStr = isSelfHosted ? "-self-hosted" : "-cloud";
  const billingStr = isAnnualBilling ? "-yearly" : "";

  // starter OR pro
  const rawPlanSlug = getRawPlanAlias(planSlug);

  // plan options
  const planSuffix = planSlug.includes(PLAN_STARTER)
    ? `${billingStr}`
    : `${deploymentStr}${billingStr}`;

  return `${rawPlanSlug}${planSuffix}` as AllPlanSlugType;
}
