import { COUNTRY_US_CODE, STATES_US } from "@common/constants/data";
import {
  Button,
  TextInput,
  Flex,
  useMantineTheme,
  Stack,
  Text,
  NativeSelect,
} from "@mantine/core";
import { ReactElement, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { customerStore } from "@stores/customer";
import { ICustomerAddress } from "@stores/customer/types";
import { DEFAULT_CUSTOMER_ADDRESS } from "@stores/customer/constants";
import SelectCountry from "@components/ui/form/SelectCountry";
import Form from "@common/components/ui/form/Form";
import InfoCard from "@common/components/thirdParty/mantine/InfoCard";
import { showNotification } from "@mantine/notifications";
import Tick from "@common/components/icons/Tick";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function ShippingAddressForm(): ReactElement {
  const theme = useMantineTheme();
  const [theLoading, setTheLoading] = useState(false);

  // customer
  const {
    isLoading: loadingCustomerDetails,
    data: customerDetails,
    error: customerDetailsError,
  } = customerStore.useGetDetails();
  const {
    mutate: updateShippingAddress,
    isLoading: loadingShippingAddress,
    error: shippingAddressError,
  } = customerStore.useUpdateShippingAddress();
  const theError = customerDetailsError || shippingAddressError || null;

  // Form
  const {
    register,
    control,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ICustomerAddress>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues:
      customerDetails?.shipping_address || DEFAULT_CUSTOMER_ADDRESS,
  });
  const watchCountry = watch("country");

  const handleStripeSubmit = useCallback(
    async (shipping_address: ICustomerAddress) => {
      setTheLoading(true);
      updateShippingAddress(
        { shipping_address },
        {
          onSuccess() {
            setTheLoading(false);
            showNotification({
              color: "green.2",
              icon: <Tick color="white" />,
              autoClose: 5000,
              message: "Shipping address updated.",
            });
          },

          onError() {
            setTheLoading(false);
          },
        },
      );
    },
    [updateShippingAddress],
  );

  const showSpinner =
    loadingCustomerDetails || loadingShippingAddress || theLoading;

  return (
    <Form
      data-testid="ShippingAddressForm"
      onSubmit={handleSubmit(handleStripeSubmit)}
      disabled={showSpinner}
    >
      <Stack spacing="sm">
        <InfoCard withBorder>
          <Text c="gray.4" fz="xs">
            The Shipping Address to be displayed alongside the Billing Address
            of your invoices.
          </Text>
        </InfoCard>

        <Flex
          align="flex-end"
          direction={{ base: "column", xs: "row" }}
          justify="flex-start"
          gap={theme.spacing.sm}
          w="100%"
        >
          <TextInput
            w="100%"
            id="street"
            label="Street address"
            placeholder="123 Main St"
            required
            {...register("street", { required: true })}
          />
          <TextInput
            w="100%"
            id="street2"
            placeholder="Unit number (optional)"
            {...register("street2")}
          />
        </Flex>

        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <SelectCountry
              data-testid="ShippingAddressForm-country"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              mt={0}
            />
          )}
        />

        <Flex
          align="flex-start"
          direction={{ base: "column", xs: "row" }}
          justify="flex-start"
          gap={theme.spacing.lg}
          w="100%"
        >
          <TextInput
            w="100%"
            id="city"
            label="City"
            placeholder="City"
            defaultValue=""
            required
            {...register("city", { required: true })}
          />

          {watchCountry === COUNTRY_US_CODE ? (
            <Controller
              name="region"
              control={control}
              render={({ field: { value, onChange } }) => (
                <NativeSelect
                  w="100%"
                  data-testid="ShippingAddressForm-region"
                  label="State"
                  data={STATES_US.map(({ value, name: label }) => ({
                    value,
                    label,
                  }))}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
          ) : (
            <TextInput
              w="100%"
              id="region"
              label="State"
              placeholder="State"
              {...register("region")}
            />
          )}

          <TextInput
            w="100%"
            id="zip"
            label="ZIP Code"
            placeholder="Zipcode"
            maxLength={20}
            required
            {...register("zip", {
              required: true,
            })}
          />
        </Flex>
        {errors.zip && <ErrorMessage m={0}>{errors.zip.message}</ErrorMessage>}

        {theError && (
          <ErrorMessage m={0}>
            {theError.message || theError.status}
          </ErrorMessage>
        )}
      </Stack>

      <Button
        type="submit"
        loading={showSpinner}
        loaderPosition="right"
        disabled={!isValid || showSpinner}
        mt="lg"
      >
        Save
      </Button>
    </Form>
  );
}
