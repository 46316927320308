import { useEffect, useState } from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { captureException } from "@sentry/nextjs";
import { IUseLoadStripeRes } from "./types";

export function useLoadStripe(stripeKey: string): IUseLoadStripeRes {
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    async function loadStripeAsync() {
      setLoading(true);

      let stripe: Stripe | null = null;
      try {
        stripe = await loadStripe(stripeKey);
        setStripe(stripe);
      } catch (err) {
        captureException(err);
      }

      setLoading(false);
    }

    if (!stripe) {
      loadStripeAsync();
    }
  }, [stripe, stripeKey]);

  return { loading, stripe };
}
