import { paymentMethodsStore } from "@stores/payment-methods";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useCallback, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, Stack, TextInput, useMantineTheme } from "@mantine/core";
import { IUpdatePaymentMethodFormProps } from "./types";
import Form from "@common/components/ui/form/Form";
import ErrorMessage from "@common/components/text/ErrorMessage";
import FormLabel from "@common/components/ui/form/FormLabel";

interface IUpdatePaymentMethodFormData {
  name: string;
}

export default function UpdatePaymentMethodForm({
  account,
}: IUpdatePaymentMethodFormProps): ReactElement {
  const theme = useMantineTheme();

  const {
    isLoading: loadingDefaultPaymentMethod,
    error: defaultPaymentMethodError,
    data: defaultPaymentMethod,
  } = paymentMethodsStore.useDefaultPaymentMethod();
  const {
    isLoading: loadingCreatePaymentMethod,
    error: stripeError,
    mutate: createPaymentMethod,
  } = paymentMethodsStore.useCreatePaymentMethod();

  const { handleSubmit, register, reset } =
    useForm<IUpdatePaymentMethodFormData>({
      shouldFocusError: true,
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    });

  const stripe = useStripe();
  const elements = useElements();

  const handleStripeSubmit = useCallback(
    async (formData: IUpdatePaymentMethodFormData) => {
      if (!stripe || !elements) {
        return;
      }

      // 1. Stripe related
      const card = elements.getElement(CardElement);
      if (!card) {
        console.error("CreatePaymentMethodForm, missing Stripe card element");
        return;
      }

      // 2. save customer PM
      createPaymentMethod(
        {
          card,
          stripe,
          billing_details: {
            ...formData,
            email: account.email,
          },
        },
        {
          onSuccess() {
            card?.clear(); // empty CC stripe form
            reset(); // reset the form
          },
        },
      );
    },
    [account, stripe, elements, createPaymentMethod, reset],
  );

  const isLoading = loadingCreatePaymentMethod || loadingDefaultPaymentMethod;

  return (
    <Form
      data-testid="UpdatePaymentMethodForm"
      onSubmit={handleSubmit(handleStripeSubmit)}
      disabled={isLoading}
    >
      <Stack spacing="sm">
        <TextInput
          id="name"
          label="Name on card"
          placeholder="Name on card"
          required
          {...register("name", { required: true })}
        />

        <Stack spacing={theme.spacing.xs / 2}>
          <FormLabel required>Credit card info</FormLabel>
          <Card id="stripe-form" withBorder p="sm">
            <CardElement
              options={{
                disableLink: true,
                hidePostalCode: true,
                style: {
                  base: {
                    fontFamily: theme.fontFamily,
                  },
                },
              }}
            />
          </Card>

          {stripeError && (
            <ErrorMessage m={0}>
              {stripeError.message || stripeError.status}
            </ErrorMessage>
          )}
        </Stack>

        {defaultPaymentMethodError && (
          <ErrorMessage m={0}>
            {defaultPaymentMethodError.message ||
              defaultPaymentMethodError.status}
          </ErrorMessage>
        )}
      </Stack>

      <Button
        type="submit"
        loading={isLoading}
        loaderPosition="right"
        disabled={isLoading}
        mt="lg"
      >
        {!defaultPaymentMethod ? "Add" : "Update"}
      </Button>
    </Form>
  );
}
