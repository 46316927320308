import {
  FEATURE_FLAG_ALLOW_NO_CC_TYPE,
  FEATURE_FLAG_CONNECTIONS_TYPE,
  FEATURE_FLAG_MAGIC_LOGIN_LINKS_TYPE,
} from "./constants";
import { IConfig } from "./types";

export function getCheckoutWithoutCC({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_ALLOW_NO_CC_TYPE)
      ?.value || false
  );
}

export function areMagicLinksAllowed({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_MAGIC_LOGIN_LINKS_TYPE)
      ?.value || false
  );
}

export function areConnectionsAllowed({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_CONNECTIONS_TYPE)
      ?.value || false
  );
}
