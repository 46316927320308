import { useRouter } from "next/router";
import { ReactElement, useCallback } from "react";
import { useHeaderNavLinkStyles } from "./HeaderNav.styled";
import { persistUTMQueryParamsToUrlObject } from "@common/utils/router";
import {
  Burger,
  Flex,
  Header,
  MediaQuery,
  NavLink,
  useMantineTheme,
} from "@mantine/core";
import {
  HEADER_NAV_HEIGHT,
  HEADER_NAV_HEIGHT_WITH_MESSAGE,
} from "@components/navigation/HeaderNav/constants";
import { IHeaderNavProps } from "./types";
import Link from "next/link";
import { SECTION_BILLING_ADDRESS } from "@pages/account/manage/billing";
import { customerStore } from "@stores/customer";
import { paymentMethodsStore } from "@stores/payment-methods";
import { purchasesStore } from "@stores/purchases";
import { HeaderNavBanner, HeaderNavLogo } from "./HeaderNav.components";
import { pluralize } from "@common/utils/string";
import { sessionStore } from "@stores/session";
import { useAccount } from "@stores/session/hooks";
import { hasDWH } from "@stores/purchases/utils";
import { isEnterprisePlan } from "@common/config/plan/utils";

export default function HeaderNav({
  opened,
  onBurgerClick,
}: IHeaderNavProps): ReactElement {
  const theme = useMantineTheme();
  const { classes: headerNavLinkStyles } = useHeaderNavLinkStyles();

  const router = useRouter();
  const { pathname } = router;

  // customer
  const { isLoading: customerDetailsLoading, data: customerDetails } =
    customerStore.useGetDetails();

  // purchases
  const { isLoading: loadingPurchases, data: purchases } =
    purchasesStore.useGetPurchases();

  // payment
  const { isLoading: loadingDefaultPaymentMethod, data: defaultPaymentMethod } =
    paymentMethodsStore.useDefaultPaymentMethod();

  // session
  const account = useAccount();
  const { mutate: logout } = sessionStore.useLogout();

  // logout
  const logoutCallback = useCallback(() => {
    logout(null, {
      onSuccess() {
        const redirectTo = encodeURIComponent(router.asPath);
        router.push(
          persistUTMQueryParamsToUrlObject(router, "/", { redirectTo }),
        );
      },
    });
  }, [logout, router]);

  // are we in one of the checkout pages? `/checkout`
  const isCheckoutInProgress = !!(pathname.indexOf("/checkout") > -1);
  const showAccountLink = !isCheckoutInProgress || !!purchases?.length;

  // purchase in trial
  const purchaseInTrial = purchases
    ?.filter(({ product }) => !isEnterprisePlan(product.plan.alias))
    .find(
      ({ hosted_instance, subscription }) =>
        subscription.in_trial && !!hosted_instance,
    );
  const purchaseInTrialHasExpired = !!(
    purchaseInTrial &&
    typeof purchaseInTrial.subscription.trial_end_days === "number" &&
    purchaseInTrial.subscription.trial_end_days < 0
  );
  const purchaseInTrialHasDWH = purchaseInTrial && hasDWH(purchaseInTrial);

  // banners
  const canHaveBanner = !isCheckoutInProgress && !customerDetailsLoading;
  const hasTrialBanner =
    canHaveBanner && !loadingPurchases && !!purchaseInTrial;
  const hasBillingAddressBanner =
    canHaveBanner &&
    !loadingDefaultPaymentMethod &&
    !!defaultPaymentMethod &&
    !customerDetails?.billing_address;
  const hasBanner = hasTrialBanner || hasBillingAddressBanner;

  return (
    <Header
      id="main-header"
      height={hasBanner ? HEADER_NAV_HEIGHT_WITH_MESSAGE : HEADER_NAV_HEIGHT}
    >
      {hasTrialBanner ? (
        <HeaderNavBanner
          text={
            purchaseInTrialHasExpired
              ? "Your trial has expired."
              : purchaseInTrial.subscription.trial_end_days
                ? `${purchaseInTrial.subscription.trial_end_days} ${pluralize("day", purchaseInTrial.subscription.trial_end_days)} left in your trial.`
                : "Your trial ends soon."
          }
          href={
            purchaseInTrialHasDWH
              ? undefined
              : pathname === "/account/manage/plans"
                ? undefined
                : "/account/manage/plans"
          }
          hrefText={
            purchaseInTrialHasExpired
              ? "Manage your subscription"
              : purchaseInTrialHasDWH
                ? undefined
                : "Buy now"
          }
        />
      ) : (
        hasBillingAddressBanner && (
          <HeaderNavBanner
            text="Looks like we don't have a billing address."
            href={`/account/manage/billing#section=${SECTION_BILLING_ADDRESS}`}
            hrefText="Add a billing address"
          />
        )
      )}

      <Flex
        align="center"
        direction="row"
        justify="space-between"
        wrap="nowrap"
      >
        <HeaderNavLogo
          account={account}
          isCheckoutInProgress={isCheckoutInProgress}
        />

        {account && (
          <>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Flex
                align="baseline"
                direction="row"
                justify="space-between"
                pr="lg"
                wrap="nowrap"
              >
                {showAccountLink && (
                  <>
                    <NavLink
                      component={Link}
                      className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                      label={`Logged in as ${account.first_name}`}
                      href="/account/manage"
                    />
                    <NavLink
                      data-testid="nav-link-logout"
                      className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                      label="Logout"
                      icon={undefined}
                      onClick={logoutCallback}
                    />
                  </>
                )}
              </Flex>
            </MediaQuery>

            {!isCheckoutInProgress && (
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={onBurgerClick}
                  size="sm"
                  color={theme.colors.gray[7]}
                  mr="xl"
                />
              </MediaQuery>
            )}
          </>
        )}
      </Flex>
    </Header>
  );
}
