import {
  IStripePaymentMethod,
  PaymentMethodsStore,
  CreatePaymentMethodMutateFunction,
  StripeWithPaymentMethodType,
} from "./types";
import { IDataFetcherResponseError } from "@common/utils/use-query/types";
import { DEFAULT_ERROR_MESSAGE } from "@common/utils/use-query/constants";
import { useMutation, useQuery, useQueryClient } from "@common/utils/use-query";
import { useState, useCallback } from "react";

export const paymentMethodsStore: PaymentMethodsStore = {
  useCreateStripeSetupIntent() {
    return useMutation({
      url: "/account/create_setup_intent",
      method: "POST",
    });
  },

  useCreatePaymentMethod() {
    const {
      isLoading: loadingStripeSetupIntent,
      error: stripeSetupIntentError,
      mutate: createStripeSetupIntent,
    } = this.useCreateStripeSetupIntent();

    // Update card
    const {
      isLoading: loadingUpdatePaymentMethod,
      error: updatePaymentMethodError,
      mutate: updatePaymentMethod,
    } = useMutation<unknown, unknown, StripeWithPaymentMethodType>({
      url: "/account/payment_methods/new",
      method: "POST",
    });

    // Properties
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IDataFetcherResponseError | null>(null);

    // Query
    const queryClient = useQueryClient();
    const createPaymentMethod = useCallback<CreatePaymentMethodMutateFunction>(
      async (variables, options) => {
        const { card, stripe, billing_details } = variables;

        setError(null);
        setIsLoading(true);

        // 1. get client secret
        createStripeSetupIntent(null, {
          onError(error) {
            setError(error);
            setIsLoading(false);
          },
          onSuccess(stripeSetupIntent) {
            const { client_secret } = stripeSetupIntent || {};
            if (!client_secret) {
              setError({
                status: 500,
                message: "Missing Stripe client_secret",
              });
              setIsLoading(false);
            } else {
              const { name, email, ...billingDetailsRest } = billing_details;

              // 2. confirm card
              stripe
                .confirmCardSetup(client_secret, {
                  payment_method: {
                    card,
                    billing_details: { name, email },
                  },
                })
                .then(({ error: confirmStripeCardSetupError, setupIntent }) => {
                  // error
                  if (confirmStripeCardSetupError) {
                    const message =
                      confirmStripeCardSetupError.code !== "incomplete_number"
                        ? confirmStripeCardSetupError.message ||
                          confirmStripeCardSetupError.code ||
                          confirmStripeCardSetupError.decline_code ||
                          DEFAULT_ERROR_MESSAGE
                        : null;
                    const status = message ? 500 : null;

                    setError(
                      status && message
                        ? {
                            status,
                            message,
                          }
                        : null,
                    );
                    setIsLoading(false);
                  } else {
                    // 3. update customer PM
                    updatePaymentMethod(
                      {
                        ...(billingDetailsRest.address || {}),
                        stripe_payment_method: String(
                          setupIntent.payment_method,
                        ),
                      },
                      {
                        onError(error, updateVariables, context) {
                          if (error?.status === 500) {
                            error = {
                              message: "Invalid credit card details",
                              status: 500,
                            };
                          }

                          setError(error);
                          setIsLoading(false);

                          options?.onError?.(error, variables, context);
                        },
                        onSuccess(data, updateVariables, context) {
                          setError(null);
                          setIsLoading(false);

                          queryClient.invalidateQueries(
                            "/account/payment_methods",
                          );

                          options?.onSuccess?.(data, variables, context);
                        },
                      },
                    );
                  }
                });
            }
          },
        });
      },
      [createStripeSetupIntent, queryClient, updatePaymentMethod],
    );

    return {
      isLoading:
        isLoading || loadingStripeSetupIntent || loadingUpdatePaymentMethod,
      error: error || stripeSetupIntentError || updatePaymentMethodError,
      mutate: createPaymentMethod,
    };
  },

  useDefaultPaymentMethod() {
    const { isLoading, error, data, refetch } = useQuery<
      unknown,
      unknown,
      IStripePaymentMethod[]
    >("/account/payment_methods");

    return {
      isLoading,
      error,
      data: data?.find(
        (paymentMethod: IStripePaymentMethod) => paymentMethod.default,
      ),
      refetch() {
        refetch();
      },
    };
  },
};
