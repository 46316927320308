import { IFormLabel } from "./types";
import { PropsWithChildren, ReactElement } from "react";
import { Text, useMantineTheme } from "@mantine/core";

export default function FormLabel({
  children,
  required,
  htmlFor,
  c = "gray.5",
  fw = 700,
  fz = "sm",
  w = "auto",
  ...textProps
}: PropsWithChildren<IFormLabel>): ReactElement<HTMLLabelElement> {
  const theme = useMantineTheme();

  return (
    <Text
      component="label"
      htmlFor={htmlFor}
      display="inline-block"
      c={c}
      fw={fw}
      fz={fz}
      pb={theme.spacing.xs / 2}
      pt={theme.spacing.xs / 3}
      w={w}
      {...textProps}
    >
      {children}
      {required && (
        <Text span c="red.3">
          &nbsp;*
        </Text>
      )}
    </Text>
  );
}
