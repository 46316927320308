export const ADDON_PRODUCT_BASE_TYPE = "base";
export const ADDON_PRODUCT_DWH_TYPE = "dwh";
export const ADDON_PRODUCT_DWH_YEARLY_TYPE = "dwh-yearly";
export const ADDON_PRODUCT_ETL_CONNECTIONS_TYPE = "etl-connections";

export const ADDON_PRODUCT_TYPES = [
  ADDON_PRODUCT_DWH_TYPE,
  ADDON_PRODUCT_DWH_YEARLY_TYPE,
] as const;

export const ADDON_PRODUCT_DWH_PREPAID_UNITS_DEFAULT = 500000;
export const ADDON_PRODUCT_DWH_PREPAID_UNITS_MIN =
  ADDON_PRODUCT_DWH_PREPAID_UNITS_DEFAULT;
export const ADDON_PRODUCT_DWH_PREPAID_UNITS_MAX = 20000000;
