import {
  ADDON_PRODUCT_BASE_TYPE,
  ADDON_PRODUCT_DWH_TYPE,
  ADDON_PRODUCT_ETL_CONNECTIONS_TYPE,
} from "@stores/addons/constants";

export const SUBSCRIPTION_STATUS_TRIAL = "trial";
export const SUBSCRIPTION_STATUS_ACTIVE = "active";
// will have this status until the end of the period
export const SUBSCRIPTION_STATUS_CANCELLATION_PENDING = "cancellation-pending";
export const SUBSCRIPTION_STATUS_CANCELLED = "cancelled";

export const SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUS_TRIAL,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLATION_PENDING,
  SUBSCRIPTION_STATUS_CANCELLED,
] as const;

export const HOSTING_FEATURE_TRIAL_UP = "trial_up";
export const HOSTING_FEATURE_CUSTOM_DOMAIN = "custom_domain";
export const HOSTING_FEATURE_INVOICING_ELIGIBILITY = "invoicing_eligibility";
export const HOSTING_FEATURE_INVOICEABLE = "invoiceable";
export const HOSTING_FEATURE_CLICKHOUSE_DWH = "clickhouse_dwh";
export const HOSTING_FEATURES = [
  HOSTING_FEATURE_TRIAL_UP,
  HOSTING_FEATURE_CUSTOM_DOMAIN,
  HOSTING_FEATURE_INVOICING_ELIGIBILITY,
  HOSTING_FEATURE_INVOICEABLE,
  HOSTING_FEATURE_CLICKHOUSE_DWH,
] as const;

export const SUBSCRIPTION_ADDON_PRODUCT_TYPES = [
  ADDON_PRODUCT_BASE_TYPE,
  ADDON_PRODUCT_DWH_TYPE,
  ADDON_PRODUCT_ETL_CONNECTIONS_TYPE,
] as const;
