import { Box, Loader, UnstyledButton, useMantineTheme } from "@mantine/core";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from "./ButtonAsText.styled";
import { IButtonAsTextProps } from "./types";

export default function ButtonAsText({
  component,
  onClick,
  loading,
  disabled,
  children,
  ...unstyledButtonProps
}: PropsWithChildren<IButtonAsTextProps>): ReactElement {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <UnstyledButton
      component={component}
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
      pos="relative"
      {...unstyledButtonProps}
    >
      <Box opacity={loading ? 0 : 1}>{children}</Box>

      {loading && (
        <Loader
          left="50%"
          pos="absolute"
          size="xs"
          top={0}
          ml={-theme.spacing.xs}
        />
      )}
    </UnstyledButton>
  );
}
