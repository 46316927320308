import { ReactElement, useCallback } from "react";
import {
  Anchor,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  List,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IConfirmationModalProps } from "./types";
import { PurchaseRow } from "./ConfirmationModal.components";
import { sessionStore } from "@stores/session";
import { showNotification } from "@mantine/notifications";
import Tick from "@common/components/icons/Tick";
import WarningCard from "@common/components/thirdParty/mantine/WarningCard";
import IconWarning from "@common/components/icons/IconWarning";
import ErrorMessage from "@common/components/text/ErrorMessage";
import { persistUTMQueryParamsToString } from "@common/utils/router";
import { useRouter } from "next/router";

export default function ConfirmationModal({
  purchases,
  opened,
  onClose,
}: IConfirmationModalProps): ReactElement | null {
  const router = useRouter();

  // purchases
  const monthlyPurchases = purchases
    ?.filter(({ product }) => product.plan.alias.indexOf("yearly") === -1)
    .filter(({ subscription }) => !subscription.is_canceled);

  // invoicing
  const {
    isLoading,
    error,
    mutate: changePaymentCollectionMethod,
    reset: resetChangePaymentCollectionMethod,
  } = sessionStore.useChangePaymentCollectionMethod();

  // form
  const handleConfirm = useCallback(() => {
    changePaymentCollectionMethod(
      { collection_method: "send_invoice" },
      {
        onSuccess() {
          onClose();
          setTimeout(() => {
            showNotification({
              color: "green.2",
              icon: <Tick color="white" />,
              autoClose: 5000,
              message: "Payment method switched to invoicing.",
            });
          }, 400);
        },
      },
    );
  }, [changePaymentCollectionMethod, onClose]);

  // styling
  const theme = useMantineTheme();

  return (
    <Modal
      data-testid="Invoicing-modal"
      centered
      onClose={() => {
        resetChangePaymentCollectionMethod();
        onClose();
      }}
      opened={opened}
      overflow="inside"
      padding={2 * theme.spacing.md}
      size="lg"
      title={
        <Title c="gray.6" size="h3">
          Switch to invoicing
        </Title>
      }
    >
      <Stack spacing="lg" w="100%">
        <Box w="100%">
          <List c="gray.5" fw={400} fz="sm" w="100%">
            <List.Item>
              Billing contacts will receive an emailed invoice for annual
              payments, and every 3 months after that for any added users.
            </List.Item>
            <List.Item>
              Invoices are due within 30 days, payable via ACH or wire transfer.
            </List.Item>
            <List.Item>
              To switch back to paying with a credit card, you&apos;ll need to
              contact our Success team.
              <br />
              You may not be eligible for a refund for annual billing.
            </List.Item>
          </List>

          {monthlyPurchases && monthlyPurchases.length > 0 ? (
            <Text c="gray.5" fw={400} fz="sm" mt="sm">
              <Anchor
                href={persistUTMQueryParamsToString(
                  router,
                  "https://www.metabase.com/docs/latest/cloud/how-billing-works#what-counts-as-a-user-account",
                )}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about how users are counted for billing.
              </Anchor>
            </Text>
          ) : (
            <Text c="gray.5" fw={400} fz="sm" mt="sm">
              <Anchor
                href={persistUTMQueryParamsToString(
                  router,
                  "https://www.metabase.com/docs/latest/cloud/how-billing-works",
                )}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about how we bill.
              </Anchor>
            </Text>
          )}
        </Box>

        {monthlyPurchases && monthlyPurchases.length > 0 && (
          <WarningCard withBorder>
            <Flex
              align="flex-start"
              direction="row"
              gap="xs"
              justify="flex-start"
              wrap="nowrap"
              w="100%"
            >
              <Box miw={theme.spacing.xl} ml={-1} mt={-2}>
                <IconWarning />
              </Box>
              <Text c="gray.5" fw={700} fz="sm">
                All your instances will switch to annual billing
              </Text>
            </Flex>
            <Text c="gray.5" fz="xs">
              As part of switching to invoicing, you&apos;ll also switch all
              instances to annual billing. Your annual payment will be included
              in your first invoice, and your credit card won&apos;t be billed.
            </Text>

            <Divider
              color="yellow.3"
              opacity={theme.other.disabled.opacity / 2}
              my="xs"
              w="100%"
            />

            <Text c="gray.5" fw={700} fz="sm">
              Summary of changes to billing
            </Text>

            {monthlyPurchases.map((purchase, index) => {
              return (
                <PurchaseRow
                  key={purchase.id}
                  index={index}
                  purchase={purchase}
                />
              );
            })}
          </WarningCard>
        )}

        <Group position="right" mt={theme.spacing.xs / 2}>
          <Button disabled={isLoading} onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleConfirm}>
            Confirm
          </Button>
        </Group>

        {error && <ErrorMessage>{error.message || error.status}</ErrorMessage>}
      </Stack>
    </Modal>
  );
}
