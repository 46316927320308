import { createContext, PropsWithChildren, ReactElement } from "react";
import { plansStore } from "./store";
import { IPlansContext } from "./types";
import { useAccount } from "@stores/session/hooks";
import { PARENT_PLANS } from "./constants";

export const PlansContext = createContext<IPlansContext>({
  plans: [],
  plansHierarchy: [],
});

export function PlansProvider({ children }: PropsWithChildren): ReactElement {
  const account = useAccount();

  const { error, data: plans = [] } = plansStore.useGetPlans({
    enabled: !!account,
  });

  if (error && account) {
    return <></>;
  }

  const plansHierarchy = PARENT_PLANS.map((parentPlanObj) => ({
    ...parentPlanObj,
    plans: plans.filter(
      (planObj) =>
        planObj.alias.includes(parentPlanObj.type) &&
        !planObj.alias.includes("-with-dwh"),
    ),
  }));

  return (
    <PlansContext.Provider value={{ plans, plansHierarchy }}>
      {children}
    </PlansContext.Provider>
  );
}
