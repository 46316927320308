import {
  Anchor,
  Badge,
  Box,
  Flex,
  MediaQuery,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { ReactElement } from "react";
import { HEADER_NAV_HEIGHT, HEADER_NAV_MESSAGE_HEIGHT } from "./constants";
import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import Link from "next/link";
import IconArrowRight from "@common/components/icons/IconArrowRight";
import { useRouter } from "next/router";
import IconInfo from "@common/components/icons/IconInfo";
import { IHeaderNavBannerProps } from "./types";
import { getEnv, isProduction } from "@common/utils/config";
import LogoMetabase from "@common/components/icons/LogoMetabase";
import LogoMetabaseStore from "@common/components/icons/LogoMetabaseStore";
import Logo from "@common/components/icons/Logo";
import type { IAccount } from "@stores/session/types";
import { useLogoLinkStyles } from "./HeaderNav.styled";

export function HeaderNavBanner({
  text,
  href,
  hrefText,
}: IHeaderNavBannerProps): ReactElement {
  const theme = useMantineTheme();

  const router = useRouter();

  return (
    <Box data-testid="HeaderNav-message" bg="yellow.3" ta="center" w="100%">
      <Flex
        align="center"
        direction="row"
        gap="sm"
        justify="flex-start"
        h={HEADER_NAV_MESSAGE_HEIGHT}
        px="xl"
        py="xs"
        w="100%"
      >
        <IconInfo size={14} />

        <Text c="gray.6" fz={{ base: "xs", xs: "sm" }}>
          {text}
        </Text>

        {href && hrefText && (
          <Anchor
            component={Link}
            href={persistUTMQueryParamsToString(router, href)}
            c="gray.6"
          >
            <Flex align="center" direction="row" justify="center">
              <Text
                span
                fw={700}
                fz={{ base: "xs", xs: "sm" }}
                mr={theme.spacing.xs / 2}
              >
                {hrefText}
              </Text>
              <IconArrowRight color={theme.colors.gray[6]} />
            </Flex>
          </Anchor>
        )}
      </Flex>
    </Box>
  );
}

function HeaderLogo({ account }: { account?: IAccount }) {
  return (
    <Flex
      align="center"
      direction="row"
      justify="center"
      h={HEADER_NAV_HEIGHT}
      px="sm"
    >
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Box ml={-9} mt={-1}>
          <Logo height={41} />
        </Box>
      </MediaQuery>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Box>{account ? <LogoMetabaseStore /> : <LogoMetabase />}</Box>
      </MediaQuery>
    </Flex>
  );
}

export function HeaderNavLogo({
  account,
  isCheckoutInProgress,
}: {
  account?: IAccount;
  isCheckoutInProgress: boolean;
}) {
  const { classes: logoLinkClasses } = useLogoLinkStyles();
  const router = useRouter();

  return (
    <Flex align="center" direction="row" wrap="nowrap" ml="lg">
      {isCheckoutInProgress ? (
        <HeaderLogo account={account} />
      ) : (
        <Link
          href={persistUTMQueryParamsToUrlObject(router, "/account")}
          className={logoLinkClasses.root}
        >
          <HeaderLogo account={account} />
        </Link>
      )}

      {!isProduction() && (
        <Badge size="xs" bg="brand.3">
          {getEnv(true)}
        </Badge>
      )}
    </Flex>
  );
}
