import { IPlanExtra, IPlanParent } from "./types";

const PARENT_PLAN_STARTER_TYPE = "starter";
const PARENT_PLAN_PRO_TYPE = "pro";
export const PARENT_PLAN_TYPES = [
  PARENT_PLAN_STARTER_TYPE,
  PARENT_PLAN_PRO_TYPE,
];

export const PARENT_PLANS: IPlanParent[] = [
  {
    type: PARENT_PLAN_STARTER_TYPE,
    title: "Starter",
    description: "Perfect for small teams or individuals",
    plans: [],
  },
  {
    type: PARENT_PLAN_PRO_TYPE,
    title: "Pro",
    description: "For larger teams & advanced use cases",
    plans: [],
  },
] as const;

// hosting
const HOSTING_FEATURE_INVOICE_ELIGIBILITY = "invoicing-eligibility";
const HOSTING_FEATURE_INVOICEABLE = "invoiceable";
const HOSTING_FEATURE_TRIAL_UP = "trial-up";
const HOSTING_FEATURE_CLICKHOUSE_DWH = "clickhouse-dwh";
const HOSTING_FEATURE_CUSTOM_DOMAIN = "custom-domain";
const HOSTING_FEATURE_DEDICATED_NODE = "dedicated-node";
const HOSTING_FEATURE_METABASE_API_KEY = "metabase-api-key";

export const HOSTING_FEATURES = [
  HOSTING_FEATURE_INVOICE_ELIGIBILITY,
  HOSTING_FEATURE_INVOICEABLE,
  HOSTING_FEATURE_TRIAL_UP,
  HOSTING_FEATURE_CLICKHOUSE_DWH,
  HOSTING_FEATURE_CUSTOM_DOMAIN,
  HOSTING_FEATURE_DEDICATED_NODE,
  HOSTING_FEATURE_METABASE_API_KEY,
] as const;

// token
const TOKEN_FEATURE_ATTACHED_DWH = "attached-dwh";
const TOKEN_FEATURE_HOSTING = "hosting";
const TOKEN_FEATURE_SSO = "sso";
const TOKEN_FEATURE_SSO_JWT = "sso-jwt";
const TOKEN_FEATURE_SSO_SAML = "sso-saml";
const TOKEN_FEATURE_SSO_LDAP = "sso-ldap";
const TOKEN_FEATURE_SSO_GOOGLE = "sso-google";
const TOKEN_FEATURE_DISABLE_PASSWORD_LOGIN = "disable-password-login";
const TOKEN_FEATURE_SESSION_TIMEOUT_CONFIG = "session-timeout-config";
const TOKEN_FEATURE_EMBEDDING = "embedding";
const TOKEN_FEATURE_AUDIT_APP = "audit-app";
const TOKEN_FEATURE_SANDBOXES = "sandboxes";
const TOKEN_FEATURE_WHITELABEL = "whitelabel";
const TOKEN_FEATURE_ADVANCED_CONFIG = "advanced-config";
const TOKEN_FEATURE_ADVANCED_PERMISSIONS = "advanced-permissions";
const TOKEN_FEATURE_EMAIL_ALLOW_LIST = "email-allow-list";
const TOKEN_FEATURE_EMAIL_RESTRICT_RECIPIENTS = "email-restrict-recipients";
const TOKEN_FEATURE_CACHE_GRANULAR_CONTROLS = "cache-granular-controls";
const TOKEN_FEATURE_DASHBOARD_SUBSCRIPTION_FILTERS =
  "dashboard-subscription-filters";
const TOKEN_FEATURE_CONFIG_TEXT_FILE = "config-text-file";
const TOKEN_FEATURE_CONTENT_MANAGEMENT = "content-management";
const TOKEN_FEATURE_CONTENT_VERIFICATION = "content-verification";
const TOKEN_FEATURE_OFFICIAL_COLLECTIONS = "official-collections";
const TOKEN_FEATURE_SNIPPET_COLLECTIONS = "snippet-collections";
const TOKEN_FEATURE_METABASE_STORE_MANAGED = "metabase-store-managed";
const TOKEN_FEATURE_NO_UPSELL = "no-upsell";
const TOKEN_FEATURE_SERIALIZATION = "serialization";
const TOKEN_FEATURE_UPLOAD_MANAGEMENT = "upload-management";

export const TOKEN_FEATURES = [
  TOKEN_FEATURE_ATTACHED_DWH,
  TOKEN_FEATURE_HOSTING,
  TOKEN_FEATURE_SSO,
  TOKEN_FEATURE_SSO_JWT,
  TOKEN_FEATURE_SSO_SAML,
  TOKEN_FEATURE_SSO_LDAP,
  TOKEN_FEATURE_SSO_GOOGLE,
  TOKEN_FEATURE_DISABLE_PASSWORD_LOGIN,
  TOKEN_FEATURE_SESSION_TIMEOUT_CONFIG,
  TOKEN_FEATURE_EMBEDDING,
  TOKEN_FEATURE_AUDIT_APP,
  TOKEN_FEATURE_SANDBOXES,
  TOKEN_FEATURE_WHITELABEL,
  TOKEN_FEATURE_ADVANCED_CONFIG,
  TOKEN_FEATURE_ADVANCED_PERMISSIONS,
  TOKEN_FEATURE_EMAIL_ALLOW_LIST,
  TOKEN_FEATURE_EMAIL_RESTRICT_RECIPIENTS,
  TOKEN_FEATURE_CACHE_GRANULAR_CONTROLS,
  TOKEN_FEATURE_DASHBOARD_SUBSCRIPTION_FILTERS,
  TOKEN_FEATURE_CONFIG_TEXT_FILE,
  TOKEN_FEATURE_CONTENT_MANAGEMENT,
  TOKEN_FEATURE_CONTENT_VERIFICATION,
  TOKEN_FEATURE_OFFICIAL_COLLECTIONS,
  TOKEN_FEATURE_SNIPPET_COLLECTIONS,
  TOKEN_FEATURE_METABASE_STORE_MANAGED,
  TOKEN_FEATURE_NO_UPSELL,
  TOKEN_FEATURE_SERIALIZATION,
  TOKEN_FEATURE_UPLOAD_MANAGEMENT,
  "cache-preemptive",
  "scim",
  "collection-cleanup",
  "database-auth-providers",
  "embedding-sdk",
] as const;

export const PLAN_ALIAS_BUSINESS = "business";
export const PLAN_ALIAS_ENTERPRISE_CLOUD = "enterprise-cloud";
export const PLAN_ALIAS_ENTERPRISE_SELF_HOSTED = "enterprise-self-hosted";

export const PLAN_ALIAS_STARTER = "starter";
export const PLAN_ALIAS_STARTER_YEARLY = "starter-yearly";
export const PLAN_ALIAS_STARTER_WITH_DWH = "starter-with-dwh";
export const PLAN_ALIAS_STARTER_WITH_DWH_YEARLY = "starter-with-dwh-yearly";

export const PLAN_ALIAS_PRO_SELF_HOSTED = "pro-self-hosted";
export const PLAN_ALIAS_PRO_SELF_HOSTED_YEARLY = "pro-self-hosted-yearly";

export const PLAN_ALIAS_PRO_CLOUD = "pro-cloud";
export const PLAN_ALIAS_PRO_CLOUD_YEARLY = "pro-cloud-yearly";
export const PLAN_ALIAS_PRO_CLOUD_WITH_DWH = "pro-cloud-with-dwh";
export const PLAN_ALIAS_PRO_CLOUD_WITH_DWH_YEARLY = "pro-cloud-with-dwh-yearly";

export const PLAN_ALIASES = [
  PLAN_ALIAS_STARTER,
  PLAN_ALIAS_STARTER_YEARLY,
  PLAN_ALIAS_STARTER_WITH_DWH,
  PLAN_ALIAS_STARTER_WITH_DWH_YEARLY,
  PLAN_ALIAS_PRO_SELF_HOSTED,
  PLAN_ALIAS_PRO_SELF_HOSTED_YEARLY,
  PLAN_ALIAS_PRO_CLOUD,
  PLAN_ALIAS_PRO_CLOUD_YEARLY,
  PLAN_ALIAS_PRO_CLOUD_WITH_DWH,
  PLAN_ALIAS_PRO_CLOUD_WITH_DWH_YEARLY,
] as const;

export const PLAN_STARTER: IPlanExtra = {
  billing_details_name: "Cloud Starter Plan",
  shortName: "Starter",
  descriptionAlt:
    "Get a managed and fully-supported cloud-hosted instance of Metabase.",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Ready to migrate from Open Source Plans",
    },
    {
      checked: false,
      description: "Basic SSO",
    },
    {
      checked: false,
      description: "Limited Embedding",
    },
    {
      checked: false,
      description: "Basic Permissions",
    },
  ],
  includesAlt: [
    {
      checked: true,
      description: "Automated upgrades and backups",
    },
    {
      checked: true,
      description: "Out-of-the-box SMTP server",
    },
    {
      checked: true,
      description: "Migrate from open source",
    },
    {
      checked: false,
      description: "Single-sign with user-group mapping via SAML, LDAP or JWT",
    },
    {
      checked: false,
      description: "Interactive Embedding: white label UI, colors, and logos",
    },
  ],
  support: "3-day email",
};

export const PLAN_STARTER_WITH_DWH: IPlanExtra = {
  billing_details_name: "Cloud Starter Plan",
  shortName: "Starter + Storage",
  extra: "500k stored rows",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Ready to migrate from Open Source Plans",
    },
    {
      checked: false,
      description: "Basic SSO",
    },
    {
      checked: false,
      description: "Limited Embedding",
    },
    {
      checked: false,
      description: "Basic Permissions",
    },
    {
      checked: true,
      description: "500k stored rows",
    },
  ],
  support: "3-day email",
};

export const PLAN_PRO_CLOUD: IPlanExtra = {
  billing_details_name: "Pro Cloud",
  shortName: "Pro Cloud",
  descriptionAlt:
    "Get extra features for embedding, managing users, and compliance.",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Single sign-on via SAML, JWT, or advanced LDAP",
    },
    {
      checked: true,
      description: "Interactive embedding",
    },
    {
      checked: true,
      description: "Whitelabeling; customize logo, colors, and more",
    },
    {
      checked: true,
      description: "Row-level permissions",
    },
    {
      checked: true,
      description: "Self-hosted or Metabase Cloud deployment",
    },
    {
      checked: true,
      description: "Ready to migrate from open source instances",
    },
    {
      checked: true,
      description: "Custom domain",
    },
  ],
  includesAlt: [
    {
      checked: true,
      description: "Automated upgrades and backups",
    },
    {
      checked: true,
      description: "Out-of-the-box SMTP server",
    },
    {
      checked: true,
      description: "Migrate from open source",
    },
    {
      checked: true,
      description:
        "Single-sign and user-group permissions mapping via SAML, LDAP or JWT",
    },
    {
      checked: true,
      description:
        "Granular row- and column-level permissions via [Data Sandboxing](Granular control over permissions that let you set strong boundaries around your data)",
    },
    {
      checked: true,
      description:
        "Granular results caching controls for faster, more responsive dashboards",
    },
    {
      checked: true,
      description:
        "Create separate staging and production environments with content and configuration version control via [Serialization](Export and import content from your Metabase)",
    },
    {
      checked: true,
      description:
        "Track the usage and performance of your Metabase with Usage [Analytics](A special collection offering total visibility into what’s going on in your instance)",
    },
    {
      checked: true,
      description:
        "Customize the appearance of your instance, including custom colors and fonts",
    },
    {
      checked: true,
      description:
        "Also includes [Interactive Embedding](The full power of Metabase in your app, matching your branding (The most awesome version of embedding)) and [Static Embedding](Basic embedding of dashboards and visualizations), without the “Powered by Metabase” badge",
    },
  ],
  support: "3-day email",
};

export const PLAN_PRO_SELF_HOSTED: IPlanExtra = {
  billing_details_name: "Pro Self-Hosted",
  shortName: "Pro Self-Hosted",
  descriptionAlt:
    "Get extra features for embedding, managing users, and compliance.",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Single sign-on via SAML, JWT, or advanced LDAP",
    },
    {
      checked: true,
      description: "Interactive embedding",
    },
    {
      checked: true,
      description: "Whitelabeling; customize logo, colors, and more",
    },
    {
      checked: true,
      description: "Row-level permissions",
    },
    {
      checked: true,
      description: "Self-hosted or Metabase Cloud deployment",
    },
    {
      checked: true,
      description: "Ready to migrate from open source instances",
    },
    {
      checked: true,
      description: "Custom domain",
    },
  ],
  includesAlt: [
    {
      checked: true,
      description: "Automated upgrades and backups",
    },
    {
      checked: true,
      description: "Out-of-the-box SMTP server",
    },
    {
      checked: true,
      description: "Migrate from open source",
    },
    {
      checked: true,
      description:
        "Single-sign and user-group permissions mapping via SAML, LDAP or JWT",
    },
    {
      checked: true,
      description:
        "Granular row- and column-level permissions via [Data Sandboxing](Granular control over permissions that let you set strong boundaries around your data)",
    },
    {
      checked: true,
      description:
        "Granular results caching controls for faster, more responsive dashboards",
    },
    {
      checked: true,
      description:
        "Create separate staging and production environments with content and configuration version control via [Serialization](Export and import content from your Metabase)",
    },
    {
      checked: true,
      description:
        "Track the usage and performance of your Metabase with Usage [Analytics](A special collection offering total visibility into what’s going on in your instance)",
    },
    {
      checked: true,
      description:
        "Customize the appearance of your instance, including custom colors and fonts",
    },
    {
      checked: true,
      description:
        "Also includes [Interactive Embedding](The full power of Metabase in your app, matching your branding (The most awesome version of embedding)) and [Static Embedding](Basic embedding of dashboards and visualizations), without the “Powered by Metabase” badge",
    },
  ],
  support: "3-day email",
};

export const PLAN_PRO_CLOUD_WITH_DWH: IPlanExtra = {
  billing_details_name: "Pro Plan",
  shortName: "Pro + Storage",
  extra: "1M stored rows",
  includes: [
    {
      checked: true,
      description: "Unlimited charts and dashboards",
    },
    {
      checked: true,
      description: "Connect to 20+ database types",
    },
    {
      checked: true,
      description: "Use 15+ visualizations",
    },
    {
      checked: true,
      description: "Single sign-on via SAML, JWT, or advanced LDAP",
    },
    {
      checked: true,
      description: "Interactive embedding",
    },
    {
      checked: true,
      description: "Whitelabeling; customize logo, colors, and more",
    },
    {
      checked: true,
      description: "Row-level permissions",
    },
    {
      checked: true,
      description: "Self-hosted or Metabase Cloud deployment",
    },
    {
      checked: true,
      description: "Ready to migrate from open source instances",
    },
    {
      checked: true,
      description: "Custom domain",
    },
    {
      checked: true,
      description: "1M stored rows",
    },
  ],
  support: "3-day email",
};

export const PLAN_EXTRAS = {
  // starter
  [PLAN_ALIAS_STARTER]: PLAN_STARTER,
  [PLAN_ALIAS_STARTER_YEARLY]: { ...PLAN_STARTER },
  [PLAN_ALIAS_STARTER_WITH_DWH]: PLAN_STARTER_WITH_DWH,
  [PLAN_ALIAS_STARTER_WITH_DWH_YEARLY]: { ...PLAN_STARTER_WITH_DWH },

  // pro cloud
  [PLAN_ALIAS_PRO_CLOUD]: PLAN_PRO_CLOUD,
  [PLAN_ALIAS_PRO_CLOUD_YEARLY]: { ...PLAN_PRO_CLOUD },
  [PLAN_ALIAS_PRO_CLOUD_WITH_DWH]: PLAN_PRO_CLOUD_WITH_DWH,
  [PLAN_ALIAS_PRO_CLOUD_WITH_DWH_YEARLY]: { ...PLAN_PRO_CLOUD_WITH_DWH },

  // pro self-hosted
  [PLAN_ALIAS_PRO_SELF_HOSTED]: PLAN_PRO_SELF_HOSTED,
  [PLAN_ALIAS_PRO_SELF_HOSTED_YEARLY]: { ...PLAN_PRO_SELF_HOSTED },
} as const;
